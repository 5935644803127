import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { HeaderProps } from '../header.props';
import { BackButton } from '@/components/backButton';
import { IconLogo } from '@/components/IconLogo';
import { useShowLogo } from '@/hooks/showLogo.hook';

export const HeaderWtf: FC<HeaderProps> = ({
  children,
  className,
  displayLogo,
  displayReturnButton,
}) => {
  const { showLogo } = useShowLogo(displayLogo);

  return (
    <div
      className={htmlClass(
        'flex flex-col px-5 mb-12 w-full',
        displayReturnButton?.display ? 'pt-6' : 'pt-14',
      )}>
      {showLogo && (
        <>
          <div className="w-full flex">
            <IconLogo />
          </div>
        </>
      )}
      {displayReturnButton?.display && (
        <div
          className={htmlClass('flex flex-col items-start ', className ?? '')}>
          {displayReturnButton && <BackButton {...displayReturnButton} />}
        </div>
      )}
      {children}
    </div>
  );
};
